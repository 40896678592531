<template>
  <div>
<section class="hero is-info is-fullheight">

            <div class="hero-body">
                <div class="container has-text-centered">
                  <div class="columns is-vcentered">
                      <div class="column is-6">
                          <h1 class="title is-size-hero has-text-left has-text-weight-bold">
                              PAGE<br>
                              NON<br>
                              TROUVÉE<br>
                          </h1>
                      </div>
                      <div class="column is-6 has-text-left">
                          <h2 class="subtitle is-size-4">
                              La page demandée n'existe pas
                          </h2>
                          <router-link tag="a" :to="{name:'Landing'}" class="button is-primary">
                              Retourner à l'accueil
                          </router-link>
                      </div>
                    </div>
                </div>
            </div>

    </section>
    
      </div>
</template>

<script>
export default {
  name: '404'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.hero {
  background: #fff url('./../../../assets/404.jpg');
  background-size:cover; 
}
.hero-body {
  background: #00000061;
}
.is-size-hero {
  font-size: 5rem;
}
</style>
